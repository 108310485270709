'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactMarkdown from 'react-markdown'
import Select from '../../Widgets/Select.react';
import DateSelector from '../../Widgets/DateSelector.react';
import { getConfigurationIssues, getPatientDemographic } from '../../../utils/Patients';
import TaxonomyActions from '../../../../actions/TaxonomyActions';
import TaxonomyStore from '../../../../stores/TaxonomyStore';
import UserStore from '../../../../stores/UserStore';
import NutritionPatternStore from '../../../../stores/NutritionPatternStore';
import { conditionSortCompare } from '../../../../utils/Conditions';
import NutritionPatternActions from '../../../../actions/NutritionPatternActions';
import AuthStore from '../../../../stores/AuthStore';
import { getConfig } from '../../../../utils/Env';
import {isEmpty} from 'lodash';
import './Conditions.scss';
import './PatientForm.scss';

export default class Conditions extends Component {
    static propTypes = {
    };

    static defaultProps = {
    };

    static contextTypes = {
        showUpgradeForm: PropTypes.func,
    }

    constructor(props) {
        super(props);

        const { patient } = props;

        const conditions = patient.conditions && patient.conditions.length
                         ? patient.conditions
                         : [{}];
        const { fetus_count = 0 } = patient;

        // Get the conditions from the patient;
        this.state = {
            conditions,
            nutritionPatterns: [],
            fetus_count,
            dirty: false,
            errors: [],
            loading: NutritionPatternStore.isLoading() || TaxonomyStore.isLoading(),
            preferences: {},
            nutritionPatterns: NutritionPatternStore.getNutritionPatterns(),
            keyIndex: TaxonomyStore.getKeyIndex(),
        };
    }

    componentDidMount = () => {
        const { nutritionPatterns, conditions } = this.state;

        if (nutritionPatterns.length && isEmpty(conditions[0])) {
            this.setDefaultConditions(nutritionPatterns);
        }

        TaxonomyActions.ensureLoaded();
        TaxonomyStore.addChangeListener(this.onTaxonomyStoreChange);
        NutritionPatternActions.load();
        NutritionPatternStore.addChangeListener(this.onNutritionPatternStoreChange);
    }

    componentWillUnmount = () => {
        NutritionPatternStore.removeChangeListener(this.onNutritionPatternStoreChange);
        TaxonomyStore.removeChangeListener(this.onTaxonomyStoreChange);
    }

    setDefaultConditions = async (nutritionPatterns) => {
        const defaultNutritionPattern = nutritionPatterns[0];
        this.setState({
            conditions: [
                {
                    name: defaultNutritionPattern?.name,
                    uuid: defaultNutritionPattern?.uuid,
                },
            ],
            dirty: true
        });
    }

    onNutritionPatternStoreChange = () => {
        const { conditions }  = this.state;
        const nutritionPatterns = NutritionPatternStore.getNutritionPatterns();

        if(nutritionPatterns.length && isEmpty(conditions[0])) {
            this.setDefaultConditions(nutritionPatterns);
        }

        this.setState({
            nutritionPatterns,
            loading: NutritionPatternStore.isLoading(),
        })
    }

    resolvePattern = async (query, pattern) => {

        const response = await AuthStore.fetch({url: getConfig('users_api') + pattern.links.resolve.href, query});

        return response;
    }

    onTaxonomyStoreChange = () => {
        this.setState({
            keyIndex: TaxonomyStore.getKeyIndex(),
            loading: TaxonomyStore.isLoading(),
        });
    }

    validate = () => {
        const { patient } = this.props;
        const { nutritionPatterns, conditions, fetus_count } = this.state;
        const errors = [];
        let error = null;

        // Get configuration issues for the current selection
        const issues = getConfigurationIssues({
            ...patient,
            conditions,
            fetus_count,
        });

        const conditionNoun = patient.practice_type === 'dietetics' ? 'condition' : 'lifestyle goal';

        if (issues.errors.length > 0) {
            error = issues.errors.join(', ');
            errors[0] = 'condition';

            this.setState({errors});

            return error;
        }

        // Loop through each condition, if the condition has a specific problem, report it
        conditions.forEach((condition, i) => {
            errors[i] = false;
            
            const pattern = nutritionPatterns.filter((pattern) => pattern.uuid === condition.uuid)[0];

            // Ignore custom conditions and show first error instead of last.
            if (!condition || error) {
                errors[i] = 'condition';
                error = `Please choose a ${conditionNoun}. If none apply, try General Healthy Diet`;
                return;
            }

            if (pattern.update_method === 'pregnancy' && !fetus_count) {
                errors[i] = 'fetus_count';
                error = 'Number of expecting is required';
                return;
            }
        });

        this.setState({errors});

        return error;
    }

    mutate = async (patient) => {
        let { dirty, conditions, nutritionPatterns, fetus_count } = this.state;
        const { originalPatient } = this.props;
        const {gender, birthdate, weight_kg, target_energy_kcal} = patient;
        const pattern = nutritionPatterns.find((pattern) => pattern.uuid === conditions[0].uuid);
        const query = {
            gender,
            birthdate,
            weight_kg,
            target_energy_kcal,
            calculate_implicit: true
        };
        const { preferences } = await this.resolvePattern(query, pattern);
        delete preferences[0].available_eating_patterns;
 
        if (!dirty) {
            return patient;
        }

        // Filter out empty conditions
        conditions = conditions.filter((condition) => condition.name);

        patient.conditions = conditions;
        patient.preferences = {...patient.preferences, ...preferences[0]};
        (originalPatient.preferences.avoidances || []).forEach((avoidance) => {
            const availableAvoidances = patient.preferences.avoidance || [];
            if(!availableAvoidances.includes(avoidance)) {
                patient.preferences.avoidance.push(avoidance);
            }
        })

        patient.pregnant = false;
        patient.lactating = false;
        patient.due_date = '';
        patient.fetus_count = 0;
        patient.next_update = patient.next_update || '';

        originalPatient.conditions?.forEach((condition) => {
            const pattern = nutritionPatterns.filter((pattern) => pattern.uuid === condition.uuid)[0];

            if (!pattern) {
                return;
            }

            if (pattern.avoidances && pattern.avoidances.length && patient.preferences && patient.preferences.avoidances) {
                pattern.avoidances.forEach(avoids => {
                    if (patient.preferences.avoidances?.includes(avoids)) {
                        patient.preferences.avoidances.splice(patient.preferences.avoidances.indexOf(avoids), 1);
                    }
                });
            }
        });

        conditions.forEach((condition) => {
            const pattern = nutritionPatterns.find((pattern) => pattern.uuid === condition.uuid)[0];

            if (!pattern) {
                return;
            }

            if (pattern.avoidances && pattern.avoidances.length) {
                patient.preferences = patient.preferences || {avoidances: []};
                patient.preferences.avoidances = patient.preferences.avoidances || [];
                pattern.avoidances.forEach(avoids => {
                    if (!patient.preferences.avoidances?.includes(avoids)) {
                        patient.preferences.avoidances.push(avoids);
                    }
                });
            }

            if (pattern.name === 'Lactation') {
                patient.lactating = true;
            }

            if (pattern.update_method === 'pregnancy') {
                patient.pregnant = true;
                patient.due_date = moment(condition.next_update).format('YYYY-MM-DD');
                patient.fetus_count = fetus_count;
            }

            if (pattern.update_method && condition.next_update) {
                if (!patient.next_update) {
                    patient.next_update = condition.next_update;
                } else if (moment(patient.next_update).isAfter(condition.next_update)) {
                    patient.next_update = condition.next_update;
                }
            }
        });

        if (patient.practice_type === 'dietetics') {
            patient.preferences = patient.preferences || {};
            patient.inhibit_change_nutrition_profile = true;
        }

        // Register ourself completed
        patient.completed = patient.completed || [];

        if (!patient.completed.includes('conditions')) {
            patient.completed.push('conditions');
        }

        return patient;
    }

    isDirty = () => {
        return this.state.dirty;
    }

    setCondition = async (uuid, i) => {
        const user = UserStore.getUser();
        const { premium_conditions } = user.capabilities || {};
        const { conditions, nutritionPatterns } = this.state;
        const { showUpgradeForm } = this.context;
        const pattern = nutritionPatterns.filter((pattern) => pattern.uuid === uuid)[0];

        // remove the condition
        if (!pattern) {
            conditions.splice(i, 1);
            this.setState({conditions, dirty: true});
            return;
        }

        if (pattern.tags.includes('Premium') && !premium_conditions) {
            showUpgradeForm({feature: 'premium_conditions'});
            return;
        }

        conditions[i] = {
            uuid: pattern.uuid,
            name: pattern.name
        };

        if (pattern.update_method === 'default') {
            conditions[i].next_update = moment().add(6, 'weeks').format();
        }

        this.setState({conditions, dirty: true, conditionsDirty: true});
    }

    setConditionData = (newData, i) => {
        const { conditions } = this.state;

        conditions[i] = {...conditions[i], ...newData};

        this.setState({conditions, dirty: true});
    }

    filterInappropriateConditions = (condition) => {
        const { patient } = this.props;

        // Get the demographic for this user and condition
        const demographic = getPatientDemographic(patient, condition.demographics);

        // Does it exist?
        return condition.templates[demographic] ? true : false;
    }

    renderCondition = (condition, i) => {
        const user = UserStore.getUser();
        const { premium_conditions } = user.capabilities || {};
        const { patient } = this.props;
        const { nutritionPatterns, errors, fetus_count, keyIndex } = this.state;

        const pattern = nutritionPatterns && nutritionPatterns.find((pattern) => pattern.uuid == condition.uuid);

        let conditionOpts = nutritionPatterns.filter((pattern) => pattern.tags.includes('Dietetics'));

        if (user.practice_type === 'fitness') {
            conditionOpts = nutritionPatterns.filter((pattern) => pattern.tags.includes('Fitness'));
        }

        conditionOpts = conditionOpts
            .sort(conditionSortCompare)
            .map(c => ({
                label: (
                    <span>
                        {user.practice_type === 'dietetics' ? c.name : (c.consumer_name || c.name)}
                        {!premium_conditions && c.premium ? <i className="icon-lock" /> : null}
                    </span>
                ), value: c.uuid
            }));

        // Did our condition make it into the options for the drop-down? If not, it's selected, but invalid. We
        // Add it, but disable it so it cannot be re-selected once changed
        if (pattern && !conditionOpts.find((condition) => (condition).value === pattern.uuid)) {
            conditionOpts.unshift({
                label: pattern.name,
                value: pattern.uuid,
                disabled: true,
            });
        }

        let expiresInOpts = [
            {label: 'no expiration', value: 0},
            {label: '1 week', value: 7},
            {label: '2 weeks', value: 7 * 2},
            {label: '3 weeks', value: 7 * 3},
            {label: '4 weeks', value: 7 * 4},
            {label: '6 weeks', value: 7 * 6},
            {label: '12 weeks', value: 7 * 12},
            {label: '24 weeks', value: 7 * 24},
        ];

        let expiresIn = 0;

        // Do we have a next_update value already? Make sure we can select it.
        if (condition.next_update) {
            expiresIn = moment(condition.next_update).diff(moment().format('YYYY-MM-DD'), 'days');

            if (expiresIn < 0) {
                expiresInOpts.push({
                    label: 'EXPIRED ' + Math.abs(expiresIn) + ' days ago',
                    value: expiresIn,
                });
            } else if (!expiresInOpts.filter(o => o.value == expiresIn)[0]) {
                expiresInOpts.push({
                    label: expiresIn + ' days',
                    value: expiresIn,
                });
            }

            expiresInOpts = expiresInOpts.sort((a, b) => a.value - b.value);
        }

        let avoidancesList = '';

        if(pattern && pattern.avoidances && pattern.avoidances.length) {
            avoidancesList = pattern.avoidances.map(avoids => {
                if (!keyIndex[avoids]) {
                    return null;
                }
                return keyIndex[avoids].title;
            }).filter(v => v).join(', ')
        }

        return (
            <div className="conditions-container" key={i}>
                <div className="with-label full-width condtion-select">
                    <Select value={condition.uuid}
                        showSearch={true}
                        placeholder={patient.practice_type === 'dietetics' ? 'Choose condition' : 'Choose goal'}
                        onChange={conditionUuid => this.setCondition(conditionUuid, i)}
                        options={conditionOpts}>
                    </Select>
                </div>

                {pattern && pattern.update_method === 'pregnancy' ?
                    <span>
                        <DateSelector label="Expected Due Date"
                            initialDate={condition.next_update}
                            data-error={errors[i] && errors[i] === 'next_update'}
                            delta_years={1}
                            onChange={next_update => this.setConditionData({next_update}, i)} />

                        <div className="with-label fetus-count full-width" data-error={errors[i] && errors[i] === 'fetus_count'}>
                            <label>How many is {patient.first_name} expecting?</label>
                            <button className="toggle-button" data-active={fetus_count === 1}
                                onClick={() => this.setState({fetus_count: 1, dirty: true})}>
                                Single
                            </button>

                            <button className="toggle-button" data-active={fetus_count === 2}
                                onClick={() => this.setState({fetus_count: 2, dirty: true})}>
                                Twins
                            </button>
                        </div>

                    </span>
                : null}
                {pattern && pattern.background ? (<div className="condition-info">
                        <ReactMarkdown source={pattern.background}/>
                        {avoidancesList.length ? <strong className="avoidances-heading">Avoidances:</strong> : null}
                        {avoidancesList.length ? avoidancesList : null}
                    </div>)
                : null}
            </div>
        );
    }

    render() {
        const { patient } = this.props;
        const { conditions, fetus_count, loading } = this.state;

        // Get configuration issues for the current selection
        const { warnings } = getConfigurationIssues({
            ...patient,
            conditions,
            fetus_count,
        });

        if (loading) {
            return (
                <div className="patient-conditions-loader">
                    <i className="icon-spinner" />
                </div>
            )
        }

        return (
            <div className="patient-form patient-conditions">
                <h6>Please select a {patient.practice_type === 'dietetics' ? 'prescription' : 'goal'}  that best applies to your {patient.practice_type === 'dietetics' ? 'patient' : 'client'}.</h6>

                {conditions.map(this.renderCondition)}

                {warnings.length > 0 ?
                    <p data-active={true} className="warning-msg">{warnings[0]}</p>
                : null}
            </div>
        );
    }
}
