'use strict'

import AppDispatcher from '../dispatcher/AppDispatcher';
import NutritionPatternConstants from '../constants/NutritionPatternConstants';

const NutritionPatternActions = {
    
    load: (params = {}) => {
        AppDispatcher.handleViewAction({
            actionType: NutritionPatternConstants.NUTRITION_PATTERN_LOAD,
            params
        });
    },

    reload: (params = {}) => {
        AppDispatcher.handleViewAction({
            actionType: NutritionPatternConstants.NUTRITION_PATTERN_RELOAD,
            params
        });
    },
};

export default NutritionPatternActions;
