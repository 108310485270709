'use strict';

import allConditions from '../tables/conditions';

export function conditionSortCompare (a, b) {
    const aName = a.name;
    const bName = b.name;

    if (a.name == 'General Healthy Diet') return -1;
    if (b.name == 'General Healthy Diet') return 1;

    return aName.localeCompare(bName);
}

export function conditionNamesToConsumerName(conditions) {
    return conditions.map(name => {
        const condition = allConditions.filter(c => c.name === name)[0];

        return condition ? (condition.consumer_name || condition.name) : name;
    });
}

export function conditionNamesToSpecialName(conditions) {
    return conditions.map(name => {
        const condition = allConditions.filter(c => c.name === name)[0];

        return condition ? (condition.special_name || condition.consumer_name || condition.name) : name;
    });
}
