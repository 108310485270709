'use strict'

import AppDispatcher from '../dispatcher/AppDispatcher';
import{ EventEmitter } from 'events';
import LocalStorage from 'store';
import moment from 'moment';
import assign from 'object-assign';

import UserConstants from '../constants/UserConstants';
import allRecommendations from '../tables/recommendations';

import { getDemographic } from '../utils/Nutrition';
import { getConfig } from '../utils/Env';

const CHANGE_EVENT = 'change';
const LOCALSTORAGE_PREFIX = 'user-store-';

var _user = {
    user: LocalStorage.get(LOCALSTORAGE_PREFIX + 'user') || null,
    altUser: LocalStorage.get(LOCALSTORAGE_PREFIX + 'alternate-user') || null,
    location: null,

    // These are just used for front-end reference, the API calls enforce their end too.
    capabilities: LocalStorage.get(LOCALSTORAGE_PREFIX + 'capabilities') || {},
    loginError: false,
    loginComplete: false,
    working: false,
    dnt: false,
};

function updateLocalStorage() {
    LocalStorage.set(LOCALSTORAGE_PREFIX + 'user', _user.user);
    LocalStorage.set(LOCALSTORAGE_PREFIX + 'alternate-user', _user.altUser);
    LocalStorage.set(LOCALSTORAGE_PREFIX + 'capabilities', _user.capabilities);
}

var UserStore = assign({}, EventEmitter.prototype, {
    getUser() {
        return _user.user;
    },

    getAlternateUser() {
        return _user.altUser;
    },

    getLocation() {
        return _user.location;
    },

    getCapabilities() {
        return _user.capabilities;
    },

    getDnt() {
        return _user.dnt;
    },

    getDemographic() {
        return getDemographic(_user.user);
    },

    /**
     * @todo - deprecate this or convert it - this should use the prescription instead if available
     */
    getRecommendedDailyValues() {
        var demographic = getDemographic(_user.user);
        var preferences = this.getRawPreferences() || {}; // We don't need computed diets here yet.

        // Does this exist in the recommendations table?
        if (!allRecommendations[demographic]) {
            return allRecommendations['all-ages'];
        }

        var recommendation = allRecommendations[demographic];

        // Now we need to pull calorie goal from the users preferences, and then
        // compute the % of sugars from that.
        //var calorieGoal = preferences.calorie_goal || 2000;
        //
        //// 50% carbs, 30% fat, 20% protein
        //recommendation[208] = calorieGoal;
        //recommendation.ASG  = Math.round(calorieGoal * 0.075 / 3.87); // added sugar should be under 7.5% of daily calorie intake
        //recommendation[269] = Math.round(calorieGoal * 0.1 / 4);      // sugar should be 10% of daily calorie intake
        //recommendation[606] = Math.round(calorieGoal * 0.1 / 9);      // saturate fat should be 10% of daily calorie intake
        //recommendation[203] = Math.round(calorieGoal * 0.2 / 4);      // calories from protein should be 20%
        //recommendation[204] = Math.round(calorieGoal * 0.3 / 9);      // total fat should be 30% of daily calorie intake
        //recommendation[205] = Math.round(calorieGoal * 0.5 / 4);      // total carbs should be 50% of daily calorie intake

        return {...allRecommendations['all-ages'], ...recommendation};
    },

    getRawPreferences() {
        return (_user.user && _user.user.preferences) || {diets: [], avoidances: []};
    },

    /**
     * Takes raw preferences (stored in UGC db) and computes the actual preference.
     *
     * Averages diets together, then applies the users specific overrides.
     */
    getPreferences() {
        return UserStore.getRawPreferences() || {diets: [], avoidances: [], leftovers_enabled: true, max_leftover_days: 1};
    },

    /**
     * Returns a stable object, if the user isn't logged in, the resulting URLs are null and
     * are thus rejected by AuthStore.fetch
     */
    getLinks() {
        const { user } = _user;

        if (!(user && user.uuid)) {
            return {
                self: null,
                tfa: null,
                preferences: null,
                boards: null,
                meals: null,
                populate: null,
                groceries: null,
                plans: null,
                family: null,
                library: null,
                subscription: null,
                confirmation: null,
                notifications: null,
                practice: null,
                patients: null,
                dietitians: null,
                icalendar: null,
                syncProfileKey: null,
                providers: null,
                oauth2: null,
                oauth2Revoke: null,
                externalData: null,
                nutritionExport: null,
            }
        }

        const { self, tfa, preferences, boards, meals, populate, groceries,
                plans, family, notifications, library, practice, patients,
                dietitians, subscription, icalendar, frequentlyUsed,
                oauth2, oauth2Revoke, providers, externalData, nutritionExport } = (user.links || {});

        const links = {
            self:            self          ? self.href          : ('/users/' + user.uuid),
            tfa:             tfa           ? tfa.href           : ('/users/' + user.uuid + '/tfa'),
            preferences:     preferences   ? preferences.href   : ('/users/' + user.uuid + '/preferences'),
            boards:          boards        ? boards.href        : ('/users/' + user.uuid + '/boards'),
            meals:           meals         ? meals.href         : ('/users/' + user.uuid + '/meals'),
            populate:        populate      ? populate.href      : ('/users/' + user.uuid + '/populate-meals'),
            groceries:       groceries     ? groceries.href     : ('/users/' + user.uuid + '/groceries'),
            plans:           plans         ? plans.href         : ('/users/' + user.uuid + '/plans'),
            family:          family        ? family.href        : ('/users/' + user.uuid + '/family'),
            library:         library       ? library.href       : ('/users/' + user.uuid + '/library'),
            notifications:   notifications ? notifications.href : ('/users/' + user.uuid + '/notifications'),
            subscription:    subscription  ? subscription.href  : ('/users/' + user.uuid + '/subscription'),
            confirmation:    user.links['send-confirmation'] ? user.links['send-confirmation'].href : ('/users/' + user.uuid + '/send-confirmation'),
            welcome:         user.links['send-welcome']      ? user.links['send-welcome'].href      : ('/users/' + user.uuid + '/send-welcome'),
            practice:        practice      ? practice.href      : null,
            patients:        patients      ? patients.href      : null,
            dietitians:      dietitians    ? dietitians.href    : null,
            icalendar:       icalendar     ? icalendar.href     : null,
            syncProfileKey:  user.links['sync-profile-key'] ? user.links['sync-profile-key'].href : ('/users/' + user.uuid + '/sync-profile-key'),
            frequentlyUsed:  frequentlyUsed ? frequentlyUsed.href : ('/users/' + user.uuid + '/frequently-used'),
            providers:       providers     ? providers.href     : ('/users/' + user.uuid + '/providers'),
            oauth2:          oauth2        ? oauth2.href        : ('/users/' + user.uuid + '/oauth2'),
            oauth2Revoke:          oauth2Revoke        ? oauth2Revoke.href        : ('/users/' + user.uuid + '/oauth2/revoke'),
            externalData:    externalData ? externalData.href : ('/users/' + user.uuid + '/external-data'),
            nutritionExport: nutritionExport ? nutritionExport.href : ('/users/' + user.uuid + '/nutrition-export'),
        };

        // Prepend the hostname to each link
        const usersApi = getConfig('users_api');
        Object.keys(links).forEach(link => links[link] = links[link] ? usersApi + links[link] : null);

        return links;
    },

    isLoginComplete: function() {
        return _user.loginComplete;
    },

    emitChange() {
        this.emit(CHANGE_EVENT);
    },

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    },

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }
});

AppDispatcher.register(function(payload) {

    switch (payload.action.actionType) {
        case UserConstants.USER_REGISTER: // fall through
            break;

        // Overwrites the entire user object
        case UserConstants.USER_COMPLETE_LOGIN:
            _user.user = payload.action.user;
            _user.capabilities = payload.action.capabilities;
            _user.location = payload.action.location;
            _user.dnt = payload.action.dnt;
            _user.loginComplete = true;
            updateLocalStorage();
            break;

        case UserConstants.USER_SUBSCRIBE:
        case UserConstants.USER_SET_PREFERENCES:
        case UserConstants.USER_CHANGE_PASSWORD:
        case UserConstants.USER_UPDATE_META:
            // Copy new user properties into our user object
            _user.user = _user.user || {};
            Object.assign(_user.user, payload.action.user);
            Object.assign(_user.capabilities, payload.action.user.capabilities);
            updateLocalStorage();
            break;

        case UserConstants.USER_CHANGE_EMAIL:
            _user.user.email = payload.action.email;
            _user.user.confirmed = 0; // This will happen on the server anyway

            updateLocalStorage();
            break;

        case UserConstants.USER_HYDRATE_LOCATION:
            const { lat, lon } = payload.action;
            _user.user.location = _user.user.location || {};
            _user.user.location.location = _user.user.location.location || {};
            _user.user.location.location.lat = lat;
            _user.user.location.location.lon = lon;

            updateLocalStorage();
            break;

        case UserConstants.USER_HYDRATE_PROFILE_KEY:
            const { profileKey } = payload.action;
            _user.user.profile_key = profileKey;
            _user.user.profile_key.last_updated = moment().format();

            updateLocalStorage();
            break;

        case UserConstants.USER_LOGOUT:
            delete _user.user;
            _user.capabilities = {};

            updateLocalStorage();
            break;

        case UserConstants.USER_SET_ALTERNATE_USER:
            _user.altUser = payload.action.altUser;

            updateLocalStorage();
            break;

        // Unrecognized type, do not emit a change
        default:
            return;
    }

    UserStore.emitChange();
});

export default UserStore;
