'use strict';

import React from 'react';
import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from '../../Widgets/Select.react';
import EnergyMethods from '../../../../utils/EnergyCalculator';
import { roundForHumans } from '../../../../utils/Math';
import { computeBmi, cmToFeetInches, getConfigurationIssues } from '../../../utils/Patients';
import { getPortionSizes, calculateNearestPortionSize} from '../../../../tables/portions';
import UserStore from '../../../../stores/UserStore';
import './EnergyCalc.scss';
import ActivityLevelSelector from '../../../../components/Input/ActivityLevelSelector.react';

export default class EnergyCalc extends Component {
    static propTypes = {
        patient: PropTypes.object.isRequired,
        height_cm: PropTypes.node,
        gender: PropTypes.string,
        birthdate: PropTypes.string,
        startTabIndex: PropTypes.number
    };

    static defaultProps = {
        startTabIndex: 0,
    };

    constructor(props) {
        super(props);

        const { patient } = props;

        const user = UserStore.getUser();
        const {
            activity_level,
            energy_method = 'mifflin',
            weekly_goal_kcal = 0,
            units_mode = 'english',
            weight_kg = '',
            kcal_per_kg = '',
            target_energy_kcal = '',
            portion = 1,
        } = patient;

        const goal_weight = units_mode === 'english'
                          ? (Math.round(patient.goal_weight_kg * 2.20462) || '')
                          : (patient.goal_weight_kg || '');

        this.state = {
            activity: activity_level,
            energy_method: energy_method || 'mifflin',
            goal_weight,
            weekly_goal_kcal: parseFloat(weekly_goal_kcal.toFixed(2)) || 0,
            target_energy_kcal: target_energy_kcal || '',
            portion: portion || 1,

            kcal_per_kg: kcal_per_kg || '',
            weight_kg: weight_kg || '',
            weight_lbs: Math.round(weight_kg * 2.20462) || '',

            // Calculated, non-editable fields.
            energy_needs_kcal: null,

            dirty: false,
            user,
            warnings: [],
        };
    }

    componentDidMount = () => {
        this.calc(false);
        this.checkIfAnyWarnings();
    }

    isDirty = () => {
        return this.state.dirty;
    }

    validateFields() {
        const { target_energy_kcal, goal_weight, weight_kg } = this.state;
        const { patient } = this.props;

        const conditions = (patient.conditions || []).map(c => c.name);

        if (conditions.includes('Pregnancy') && !weight_kg) {
            return {'message': 'Pre-pregnancy weight is required.', 'field': 'weight'};
        }

        if (!target_energy_kcal) {
            return {'message': 'Target Energy Needs are required.', 'field': 'target_energy_kcal'};
        }

        if (!parseInt(target_energy_kcal)) {
            return {'message': 'Target Energy Needs must be a positive integer.', 'field': 'target_energy_kcal'};
        }

        if (goal_weight && !parseFloat(goal_weight)) {
            return {'message': 'Goal weight must be a positive number.', 'field': 'goal_weight'};
        }

        return true;
    }

    validate = () => {
        const { target_energy_kcal, portion, weight_kg } = this.state;
        const { patient } = this.props;

        const fieldsValidated = this.validateFields();

        if (true !== fieldsValidated) {
            this.setState({error: fieldsValidated.field});
            return fieldsValidated.message;
        }

       // Get configuration issues for the current selection
        const issues = getConfigurationIssues({
            ...patient,
            target_energy_kcal,
            portion,
            weight_kg,
        });

        if (issues.errors.length) {
            this.setState({error: 'target_energy_kcal'});

            return issues.errors.join(', ');
        }

        return true;
    }

    mutate = async (patient) => {
        const {
            activity, energy_method, weight_kg,
            goal_weight, weekly_goal_kcal,
            target_energy_kcal, portion
        } = this.state;

        if (!this.isDirty()) {
            return patient;
        }

        patient.activity_level = String(activity) + '';
        patient.energy_method = energy_method;
        patient.weekly_goal_kcal = weekly_goal_kcal;
        patient.target_energy_kcal = parseInt(target_energy_kcal);

        patient.goal_weight_kg = patient.units_mode === 'metric'
                                ? parseFloat(goal_weight)
                                : parseFloat(goal_weight) * 0.453592;
        patient.weight_kg = parseFloat(weight_kg);
        patient.portion = portion;
        patient.completed = patient.completed || [];

        if (!patient.completed.includes('energy')) {
            patient.completed.push('energy');
        }

        return patient;
    }

    calc = (setTarget = true) => {
        const { patient } = this.props;
        const { user, activity, kcal_per_kg, weight_kg, weekly_goal_kcal } = this.state;
        const energyMethod = EnergyMethods.filter(em => em.prop === this.state.energy_method)[0];

        if (!energyMethod) {
            this.setState({
                energy_needs_kcal: null,
            });
            return;
        }

        const { birthdate, height_cm } = patient;

        let age = null, ageDays = null;

        if (moment(birthdate).isValid()) {
            age = moment().diff(birthdate, 'year');
            ageDays = moment().diff(birthdate, 'day');
        }

        const params = {
            gender: patient.gender,
            weightKg: weight_kg,
            heightCm: height_cm,
            kcalPerKg: kcal_per_kg,
            activity,
            age,
            ageDays,
        };

        const energy_needs_kcal = energyMethod.compute(params);

        if (energy_needs_kcal === false) {
            this.setState({
                energy_needs_kcal: null,
            });

            return;
        }

        const newState = {
            energy_needs_kcal: Math.round(energy_needs_kcal),
        };

        if (setTarget) {
            let target_energy_kcal = energy_needs_kcal;
            let portion = null;

            if (patient.pregnant) {
                const { due_date, fetus_count = 1 } = patient;
                const weeksPregnant = moment().diff(moment(due_date).subtract(42, 'weeks'), 'weeks');

                // First Trimester - NO ADDITIONAL CALORIE NEEDED
                // Second Trimester
                if (weeksPregnant >= 14 && weeksPregnant <= 27) {
                    // 340 kcal for the first baby, and 175 kcal for each additional
                    target_energy_kcal += 340 + (fetus_count - 1) * 175;
                }

                // Third Trimester
                if (weeksPregnant >= 28) {
                    target_energy_kcal += 450 + (fetus_count - 1) * 225;
                }
            } else if (patient.lactating) {
                target_energy_kcal += 500;
            } else {
                let weeklyGoal = parseFloat(weekly_goal_kcal);

                // Do we have a goal weight set? Adds proportional amount of calories
                if (weeklyGoal) {
                    target_energy_kcal += weeklyGoal;
                }
            }

            newState.target_energy_kcal = roundForHumans(target_energy_kcal);
            newState.portion = this.getBestPortionSize(target_energy_kcal);
        }

        this.setState(newState);
    }

    getPortionResolution = () => {
        return this.props.patient.portion_resolution ? this.props.patient.portion_resolution : 0.25;
    }

    getBestPortionSize = (kcal) => {
        const { user } = this.state;
        const { standard_calories = 2000, inhibit_deadzone = false } = user?.features?.energy_estimator || {};

        return calculateNearestPortionSize(
            kcal,
            this.getPortionResolution(),
            standard_calories,
            inhibit_deadzone
        );
    }

    onChangeEnergyNeeds = (target_energy_kcal) => {
        this.setState({
            dirty: true,
            target_energy_kcal,
            portion: this.getBestPortionSize(target_energy_kcal),
        });
    }

    onBlurEnergyNeeds = (target_energy_kcal) => {
        this.setState({portion: this.getBestPortionSize(target_energy_kcal), dirty: true}, this.checkIfAnyWarnings);
    }

    checkIfAnyWarnings() {
        const { warnings } = getConfigurationIssues({
            ...this.props.patient,
            portion:this.state.portion,
            weight_kg: this.state.weight_kg,
            target_energy_kcal: this.state.target_energy_kcal,
        });

        this.setState({ warnings });
    }


    onBlurGoalWeight = () => {
        // Compare goal_weight_kg to weight_kg.
        const { patient } = this.props;
        const { goal_weight, weight_kg } = this.state;

        if (!goal_weight) {
            return;
        }

        const { units_mode } = patient;

        const goal_weight_kg = units_mode === 'metric'
                             ? Math.round(parseFloat(goal_weight))
                             : Math.round(parseFloat(goal_weight) * 0.453592);

        if (goal_weight_kg > weight_kg * 1.025) {
            // Gain weight
            this.setState({weekly_goal_kcal: 500}, this.calc);
        } else if (goal_weight_kg < weight_kg * .975) {
            // Lose weight
            this.setState({weekly_goal_kcal: -500}, this.calc);
        } else {
            this.setState({weekly_goal_kcal: 0}, this.calc);
        }
    }

    onChangeWeightLbs = (ev) => {
        const weight_lbs = ev.target.value;

        const weight_kg = (Math.round(weight_lbs * 0.453592 * 10) / 10) || '';

        this.setState({weight_lbs, weight_kg, dirty: true}, this.calc);
    }

    onChangeWeightKg = (ev) => {
        const weight_kg = ev.target.value;

        const weight_lbs = (Math.round(weight_kg / 0.453592 * 10) / 10) || '';

        this.setState({weight_kg, weight_lbs, dirty: true}, this.calc);
    }

    renderPatientGeometry = () => {
        const { weight_kg } = this.state;
        const { patient } = this.props;
        const { units_mode } = patient;

        let height = (patient.height_cm + ' cm');
        let weight = (weight_kg + ' kg');

        if (units_mode === 'english') {
            let { feet, inches } = cmToFeetInches(patient.height_cm);

            height = `${feet}' ${inches}"`;
            weight = Math.round(weight_kg * 2.20462) + ' lbs';
        }

        return (
            <ul className="patient-geometry">
                <li>Height: <em>{height}</em></li>
                <li>Weight: <em>{weight}</em></li>
                <li>BMI: <em>{computeBmi(weight_kg, patient.height_cm)}</em></li>
            </ul>
        );
    }

    render() {
        let { patient, startTabIndex: tabIndex } = this.props;
        const { units_mode = 'metric' } = patient;
        const { user,
                error,
                kcal_per_kg,
                goal_weight,
                weekly_goal_kcal,
                energy_method,
                activity,
                energy_needs_kcal,
                weight_kg, weight_lbs,
                target_energy_kcal,
                portion, warnings } = this.state;

        const energyMethod = EnergyMethods.filter(em => em.prop === energy_method)[0] || EnergyMethods[0];

        let bd = moment(patient.birthdate);

        if (!(bd && bd.isValid())) {
            bd = null;
        }

        const energyMethodsOps = EnergyMethods.map(method => {
            // Older than required?
            let disabled = false;

            if (method.maxAge && bd && -bd.diff(moment(), 'year') > method.maxAge) {
                disabled = true;
            }

            return {
                value: method.prop,
                label: method.label,
                disabled,
            };
        });


        const weeklyGoalOptions = units_mode === 'metric' ?
        [
            {value:  parseFloat((-500 * 2.20462).toFixed(2)), label: <span>Lose 1 kgs/Week</span>},
            {value:  parseFloat((-375 * 2.20462).toFixed(2)), label: <span>Lose &frac34; kgs/Week</span>},
            {value:  parseFloat((-250 * 2.20462).toFixed(2)), label: <span>Lose &frac12; kgs/Week</span>},
            {value:  parseFloat((-125 * 2.20462).toFixed(2)), label: <span>Lose &frac14; kgs/Week</span>},
            {value:     0, label: <span>Maintain Weight</span>},
            {value:  parseFloat((125 * 2.20462).toFixed(2)), label: <span>Gain &frac14; kgs/Week</span>},
            {value:  parseFloat((250 * 2.20462).toFixed(2)), label: <span>Gain &frac12; kgs/Week</span>},
            {value:  parseFloat((375 * 2.20462).toFixed(2)), label: <span>Gain &frac34; kgs/Week</span>},
            {value:  parseFloat((500 * 2.20462).toFixed(2)), label: <span>Gain 1 kgs/Week</span>},
        ]
        : [
            {value: -1000, label: <span>Lose 2 lbs/Week</span>},
            {value:  -750, label: <span>Lose 1&frac12; lbs/Week</span>},
            {value:  -500, label: <span>Lose 1 lbs/Week</span>},
            {value:  -250, label: <span>Lose &frac12; lbs/Week</span>},
            {value:     0, label: <span>Maintain Weight</span>},
            {value:   250, label: <span>Gain &frac12; lbs/week</span>},
            {value:   500, label: <span>Gain 1 lbs/week</span>},
            {value:   750, label: <span>Gain 1&frac12; lbs/Week</span>},
            {value:  1000, label: <span>Gain 2 lbs/week</span>},
        ];

        const recommendedPortion = this.validateFields() && !!activity && !!target_energy_kcal ?
            this.getBestPortionSize(target_energy_kcal) : null;
        const portionSizeOpts = getPortionSizes(this.getPortionResolution(), recommendedPortion);

        return (
            <div className="patient-form energy-calculator">
                {this.renderPatientGeometry()}

                <div className="fields">
                    <div className="with-label energy-method">
                        <label>Energy Needs Method</label>
                        <Select value={energy_method}
                            placeholder="Energy Method"
                            tabIndex={tabIndex++}
                            options={energyMethodsOps}
                            onChange={energy_method => this.setState({energy_method, activity: null, dirty: true}, this.calc)}
                            data-error={error === 'energy_method'}>
                            <p>Energy Needs Formula</p>
                        </Select>
                    </div>

                    {energyMethod.activity_levels ?
                        <ActivityLevelSelector
                            value={activity}
                            options={energyMethod.activity_levels}
                            onChange={activity => this.setState({activity, dirty: true}, this.calc)}
                            className={'energy-method'}
                            tabIndex={tabIndex++}
                            title="Choose level of activity"
                            error={error == 'activity'} />
                    : null}

                    {energyMethod.showMinMax ?
                        <div className="with-label kcal-kg-container" data-error={error == 'kcal_kg'}>
                            <label>kcal/kg</label>
                            <input type="text"
                                tabIndex={tabIndex++}
                                value={kcal_per_kg}
                                onChange={ev => this.setState({kcal_per_kg: ev.target.value, dirty: true}, this.calc)} />
                        </div>
                    : null}

                    {!(patient.pregnant || patient.lactating) ?
                        <div className="with-label goal-weight">
                            <label>Goal Weight <em>(optional)</em></label>

                            <div className="with-units">
                                <input type="text"
                                    value={goal_weight} tabIndex={tabIndex++}
                                    onBlur={this.onBlurGoalWeight}
                                    onChange={ev => this.setState({goal_weight: ev.target.value, dirty: true})} />
                                {units_mode === 'english' ? <label>lbs</label> : null}
                                {units_mode === 'metric' ? <label>kg</label> : null}
                            </div>
                        </div>
                    : null}

                    {!(patient.pregnant || patient.lactating) ?
                        <div className="with-label weekly-goal">
                            <label>Weekly goal</label>
                            <Select value={weekly_goal_kcal}
                                tabIndex={tabIndex++}
                                placeholder="Weekly Goal"
                                options={weeklyGoalOptions}
                                onChange={weekly_goal_kcal => this.setState({weekly_goal_kcal, dirty: true}, this.calc)}>
                                <p>Weekly Goal</p>
                            </Select>
                        </div>
                    : null}

                    {patient.pregnant ?
                        <div className="with-label goal-weight">
                            <label>Pre-pregnancy Weight</label>

                            <div className="with-units">
                                <input type="text"
                                    data-error={error === 'weight'}
                                    value={units_mode === 'metric' ? weight_kg : weight_lbs} tabIndex={tabIndex++}
                                    onChange={units_mode === 'metric' ? this.onChangeWeightKg : this.onChangeWeightLbs} />
                                {units_mode === 'english' ? <label>lbs</label> : null}
                                {units_mode === 'metric' ? <label>kg</label> : null}
                            </div>
                        </div>
                    : null}

                    <div className="with-label computed-energy-needs">
                        <label>Energy Needs (maintenance)</label>
                        <div className="computed-energy-kcal">{energy_needs_kcal ? <em>{energy_needs_kcal} kcal/day</em> : <span>&mdash;</span>}</div>
                    </div>

                    <div className="with-label target-energy-needs">
                        <label>Target Energy Needs</label>
                        <div className="with-units target-energy-kcal">
                            <input type="text" tabIndex={tabIndex++}
                                value={target_energy_kcal} data-error={error === 'target_energy_kcal'}
                                onChange={ev => this.onChangeEnergyNeeds(ev.target.value)}
                                onBlur={ev => this.onBlurEnergyNeeds(ev.target.value)}
                                />
                            <label>kcal</label>
                        </div>
                    </div>

                    <div className="with-label portion-size">
                        <label>Portion Size</label>
                        <div className="with-units target-energy-kcal">
                            <Select optionClassName="sub-label-short" options={portionSizeOpts} value={portion} onChange={portion => this.setState({portion, dirty: true})} />
                        </div>
                    </div>

                    {warnings.length > 0 ?
                        <p data-active={true} className="warning-msg">{warnings[0]}</p>
                    : null}
                </div>
            </div>
        );
    }
}
